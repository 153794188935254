import { useStorage } from '@vueuse/core';
import type { Price } from '~/interfaces/Boardgame';
import type { Cart } from '~/interfaces/Cart';
import type { CategoryBoardgame } from '~/interfaces/Category';

export const useCart = () => {
  const toast = useToast();
  const cart = useState<Cart>('cart', () => ({} as Cart));
  const cartCounter = useCookie<number>('cart-counter');
  const loading = ref(false);
  const discount = ref(0);

  const loadCart = async () => {
    try {
      loading.value = true;

      cart.value = useStorage('cart', {
        products: [],
        payment: undefined,
        shippingMethod: undefined,
      });

      cartCounter.value = Array.isArray(cart.value.products)
        ? cart.value.products.length
        : 0;
    } finally {
      loading.value = false;
    }
  };

  const cartAdd = (product: CategoryBoardgame, selectedPrice: Price) => {
    loading.value = true;
    try {
      if (!cart.value.products) {
        cart.value.products = [];
      }

      if (cart.value.products.find((item) => item.id === product.id)) {
        toast.add({ title: 'Este item já está no carrinho', timeout: 2000 });
        return;
      }

      cart.value?.products?.push({ ...product, price: selectedPrice });

      toast.add({ title: 'Item adicionado ao carrinho', timeout: 2000 });
    } finally {
      loading.value = false;
    }
  };

  const removeProductFromCart = (id: string) => {
    cart.value.products = cart.value.products.filter((item) => item.id !== id);
    if (cart.value.products.length === 0) {
      clearCart();
    }
  };

  const totalItemsInCart = computed(() => {
    return cart?.value?.products?.length || 0;
  });

  const amountTotal = computed(() => {
    let total = 0.0;
    cart.value?.products?.forEach((item) => {
      total += Number(item.price.price);
    });

    return Number(total).toFixed(2);
  });

  const subtotal = computed(() => {
    return Number(amountTotal.value) + Number(amountTotal.value) * 0.1;
  });

  const cartPriceCategory = computed(() => {
    if (cart?.value?.products?.length > 0) {
      return cart.value.products[0].price;
    }
  });

  const rentDays = computed(() => {
    if (cart?.value?.products?.length >= 1) {
      const priceCategoryDays = cart.value?.products?.map(
        (item) => item.price.days
      );
      const maxDay = Math.max(...priceCategoryDays);
      return maxDay;
    }

    return 0;
  });

  const clearCart = async () => {
    cart.value = {
      products: [],
      payment: undefined,
      shippingMethod: '',
      pickup_date: '',
      return_date: '',
    };
  };

  watch(
    () => cart.value.products,
    () => {
      cartCounter.value = Number(cart.value?.products?.length || 0);
    },
    { immediate: true }
  );

  return {
    cart,
    cartCounter,
    cartAdd,
    removeProductFromCart,
    loadCart,
    loading,
    amountTotal,
    subtotal,
    cartPriceCategory,
    discount,
    rentDays,
    totalItemsInCart,
    clearCart,
  };
};
